<template>
  <div>
    <v-text-field
      v-show="!sendConfirmationCode"
      v-model="userPhone"
      @input="formatNumber()"
      @keypress="restrictInput"
      :rules="[rules.required]"
      @focus="reset"
      required
      label="Номер телефона"
      placeholder="+7 ("
      maxlength="18"
      persistent-placeholder
      outlined
      :error-messages="userPhoneErr"
      tabindex="2"
    />

    <v-select
      v-show="!sendConfirmationCode"
      v-model="selectedOrganization"
      label="Выберите организацию"
      :clearable="false"
      :items="listOrganizations"
      :item-text="(item) => item.Name"
      return-object
      persistent-placeholder
      outlined
      :error-messages="selectedOrganizationError"
      tabindex="3"
    />

    <div
      v-if="sendConfirmationCode"
      class="d-flex justify-center align-center flex-sm-wrap"
    >
      <strong style="margin-bottom: 10px">Введите СМС-код</strong>
      <span>Отправлен на номер +*(***) ***-**-{{ secretUserPhone }}</span>
      <otp-input
        ref="otpInput"
        :input-classes="!confirmationCodeError ? 'otp-input' : 'otp-input-err'"
        separator=" "
        :num-inputs="4"
        @on-change="handleOtpInput"
        @on-complete="localSendSmsCode"
        :should-auto-focus="true"
        :is-input-num="true"
      />
      <div
        class="mb-4"
        :style="'color: var(--v-blue-grey-base) !important; fontWeight: normal; '"
      >
        <span v-if="hideRetryButton">
          Новый код можно запросить через 0:{{ timeLeft }}</span
        >
        <a
          v-if="!hideRetryButton"
          :style="'textDecoration: underline'"
          @click="localGenerateSmsCode()"
        >
          Отправить код еще раз
        </a>
      </div>
    </div>

    <div
      :class="[
        'flex-container',
        !sendConfirmationCode ? 'justify-space-between' : 'justify-end',
      ]"
    >
      <a
        v-if="!sendConfirmationCode"
        :style="'color: var(--v-blue-grey-base) !important; fontWeight: normal; textDecoration: underline'"
        @click="$emit('toggleResetPassword')"
        tabindex="5"
      >
        Вернуться назад
      </a>
      <v-btn
        v-if="!sendConfirmationCode"
        class="btn-normale"
        color="primary"
        large
        dark
        :disabled="!selectedOrganization"
        @click="localGenerateSmsCode()"
        tabindex="6"
      >
        Сбросить пароль
      </v-btn>
      <v-btn
        v-if="sendConfirmationCode"
        class="btn-normale"
        color="primary"
        large
        dark
        @click="$emit('toggleSendConfirmationCode')"
        tabindex="7"
      >
        Отмена
      </v-btn>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  components: {
    OtpInput,
  },
  props: {
    sendConfirmationCode: { type: Boolean, default: false },
    userPhoneError: { type: String, default: null },
    confirmationCodeError: { type: Boolean, default: false },
  },
  data() {
    return {
      userPhone: "",
      listOrganizations: [],
      selectedOrganization: null,
      secretUserPhone: null,
      userPhoneErr: null,
      selectedOrganizationError: null,
      hideRetryButton: false,
      timeLeft: null,
      countdownInterval: null,

      rules: {
        required: (value) => !!value || "Укажите номер",
      },
    };
  },
  watch: {
    userPhone(newValue) {
      if (newValue.length === 18) this.secretUserPhone = newValue.slice(-2);
    },
    userPhoneError(newValue) {
      this.userPhoneErr = newValue;
    },
    selectedOrganization(newValue) {
      if (newValue) this.selectedOrganizationError = null;
    },
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    restrictInput(event) {
      const charCode = event.charCode;
      // Разрешаем только цифры (0-9)
      if ((charCode < 48 || charCode > 57) && charCode !== 43) {
        event.preventDefault();
      }
    },
    async formatNumber() {
      let input = this.userPhone || "";

      // Удаляем все нецифровые символы
      input = input.replace(/\D/g, "");

      if (input.startsWith("9")) {
        input = "7" + input;
      }

      if (input.length !== 0) {
        // Форматируем номер
        let formattedNumber = "+7 (";
        if (input.length >= 1) {
          formattedNumber +=
            input.substring(1, 4) + (input.length >= 4 ? ") " : "");
        }
        if (input.length >= 5) {
          formattedNumber += input.substring(4, 7);
        }
        if (input.length >= 8) {
          formattedNumber += "-" + input.substring(7, 9);
        }
        if (input.length >= 10) {
          formattedNumber += "-" + input.substring(9, 11);
        }

        this.userPhone = formattedNumber;

        if (input.length === 11) {
          this.getOrganizationsWithPhone(input);
        } else {
          this.userPhoneErr = null;
          this.listOrganizations = [];
          this.selectedOrganization = null;
          this.selectedOrganizationError = null;
        }
      }
    },
    handleOtpInput() {
      this.reset();
    },
    async getOrganizationsWithPhone(input) {
      const response = await AuthService.getOrganizationsWithPhone(input);
      if (response.data.length === 0) {
        this.userPhoneErr =
          "Номер не зарегистрирован. Обратитесь к администратору.";
      } else {
        this.listOrganizations = response.data;
        if (this.listOrganizations.length === 1) {
          this.selectedOrganization = this.listOrganizations[0];
        } else {
          this.selectedOrganizationError = "Выберите организацию";
        }
      }
    },
    localGenerateSmsCode() {
      this.$emit(
        "generateSmsCode",
        this.userPhone,
        this.selectedOrganization.Code
      );

      this.hideRetryButton = true;
      this.timeLeft = 59;

      this.countdownInterval = setInterval(() => {
        this.timeLeft--;

        this.timeLeft =
          this.timeLeft < 10 ? `0${this.timeLeft}` : this.timeLeft;

        if (this.timeLeft <= 0) {
          clearInterval(this.countdownInterval);
          this.hideRetryButton = false;
        }
      }, 1000);
    },
    localSendSmsCode(value) {
      this.$emit(
        "sendSmsCode",
        this.userPhone,
        this.selectedOrganization.Code,
        value
      );
    },
  },
};
</script>
<style lang="scss">
.otp-input {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 60px;
  padding: 5px;
  margin: 30px 10px 30px 10px;
  font-size: 20px;
  border-radius: 4px;
  background-color: #e8ecff;
  border: 1px solid #919ab5;
  text-align: center;
  &:focus {
    border: 1px solid #2757ff;
    outline: none;
    background-color: #f0f8ff;
  }
}
.otp-input-err {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 60px;
  padding: 5px;
  margin: 30px 10px 30px 10px;
  font-size: 20px;
  border-radius: 4px;
  background-color: #e8ecff;
  border: 1px solid red;
  text-align: center;
  &:focus {
    border: 1px solid red;
    outline: none;
    background-color: #f0f8ff;
  }
}
</style>
